import React from  "react"
import { Link } from "gatsby"

import SEO from "../seo"

import WorkshopsFullDesc from "../WorkshopsFullDesc"

import Layout from "../Layout"
import Contact from "../Contact";

const IndexPage = ({ lang }) => (
  <Layout lang={lang}>
    <WorkshopsFullDesc lang={lang} />
    <Contact lang={lang} />
  </Layout>
)

export default IndexPage